import React, { useState, useEffect } from "react";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoCloseCircleSharp } from "react-icons/io5";
import StatusTicks from "./StatusTicks";
import MessageDialog from "./MessageDialog";
import ReactionDialog from "./ReactionDialog";
import ReactionManagementDialog from "./ReactionManagementDialog";
import { imgUrl } from "../utils/services";
import {
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileImage,
} from "react-icons/fa";
import PollMessage from "./PollMessage";

function MessageBubble({
  msg = {},
  type,
  time,
  isLink,
  img,
  audio,
  sent,
  status,
  profileImage,
  onProfilePicClick,
  onEditMessage,
  onDeleteMessage,
  onPinMessage,
  onReplyMessage,
  replyingTo,
  files = [],
  poll,
  onUnpinMessage,
  isPinned,
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [editMessage, setEditMessage] = useState(msg);
  const [isEditing, setIsEditing] = useState(false);
  const [isReactionDialogOpen, setIsReactionDialogOpen] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [isReactionManagementDialogOpen, setIsReactionManagementDialogOpen] =
    useState(false);

  const handleVote = (pollIndex, optionIndex) => {
    console.log(`Poll ${pollIndex} option ${optionIndex} selected`);
  };

  const handleIconClick = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleEditMessage = () => {
    setIsEditing(true);
    setIsDialogOpen(false);
  };

  const handleMessageChange = (e) => {
    setEditMessage(e.target.value);
  };

  const handleMessageSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onEditMessage(editMessage);
      setIsEditing(false);
    }
  };

  const handleReply = () => {
    onReplyMessage({ id: msg.id, msg });
  };

  const handleDelete = (option) => {
    onDeleteMessage(option);
    setIsDialogOpen(false);
    setSelectedReaction(null);
  };

  const handleProfileClick = () => {
    setIsProfileDialogOpen(true);
  };

  const handleCloseProfileDialog = () => {
    setIsProfileDialogOpen(false);
  };

  const handleReact = () => {
    setIsDialogOpen(false);
    setIsReactionDialogOpen(true);
  };

  const handleReactionSelect = (reaction) => {
    setSelectedReaction(reaction);
    setIsReactionDialogOpen(false);
  };

  const handleCloseReactionDialog = () => {
    setIsReactionDialogOpen(false);
  };

  const handleReactionManagementClick = () => {
    setIsReactionManagementDialogOpen(!isReactionManagementDialogOpen);
  };

  const handleRemoveReaction = () => {
    setSelectedReaction(null);
  };

  const handleCloseReactionManagementDialog = () => {
    setIsReactionManagementDialogOpen(false);
  };

  const handlePinToggle = () => {
    if (isPinned) {
      if (typeof onUnpinMessage === "function") {
        onUnpinMessage(msg._id);
      } else {
        console.error("onUnpinMessage function error.");
      }
    } else {
      if (typeof onPinMessage === "function") {
        onPinMessage();
      } else {
        console.error("onPinMessage function error.");
      }
    }
    setIsDialogOpen(false);
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return <FaFilePdf className="text-red-600" />;
      case "doc":
      case "docx":
        return <FaFileWord className="text-blue-600" />;
      case "xls":
      case "xlsx":
        return <FaFileExcel className="text-green-600" />;
      case "ppt":
      case "pptx":
        return <FaFilePowerpoint className="text-orange-600" />;
      default:
        return <FaFileImage className="text-amber-300" />;
    }
  };

  const messageMargin = selectedReaction ? "mb-4" : "my-2";
  const isImage =
    img || (files.length > 0 && files[0]?.type?.startsWith("image/"));

  const messageType = isImage
    ? "image"
    : files.length > 0
    ? "file"
    : audio
    ? "audio"
    : isLink
    ? "link"
    : poll
    ? "poll"
    : "text";

  const isDeleted = msg && typeof msg === "object" && msg.isDeleted;

  return (
    <div
      className={`relative flex flex-col rounded-md p-2 mb-2 ${
        sent ? "bg-[#005c4b] ml-auto" : "bg-[#202d33] mr-auto"
      }`}
      style={{ width: "fit-content", minWidth: "80px", maxWidth: "100%" }}
    >
      <div className="relative flex flex-row items-center">
        {!sent && (
          <div className="relative">
            <img
              src={`${imgUrl}${profileImage}`}
              alt="User Profile"
              className="w-8 h-6 rounded-full mr-2 cursor-pointer"
              onClick={handleProfileClick}
            />
            {isProfileDialogOpen && (
              <div className="absolute top-10 left-0 bg-opacity- p-4 rounded-md shadow-md w-48 z-50">
                <div className="relative">
                  <img
                    src={`${imgUrl}${profileImage}`}
                    alt="Profile"
                    className="w-24 h-24 rounded-full mx-auto"
                  />
                  <IoCloseCircleSharp
                    className="absolute top-1 right-1 text-red-500 text-xl cursor-pointer"
                    onClick={handleCloseProfileDialog}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col w-full h-full text-center">
          <div
            className="flex flex-col w-full h-full ml-1"
            style={{ wordBreak: "break-word" }}
          >
            {isEditing ? (
              <textarea
                value={editMessage}
                onChange={handleMessageChange}
                onKeyDown={handleMessageSubmit}
                className="w-full p-2 rounded-md text-black"
                autoFocus
              />
            ) : isImage ? (
              <div className="relative w-100">
                <img
                  src={img || files[0]?.url}
                  alt="message_content"
                  className="rounded-md max-w-[270px] w-100"
                />
                <a
                  href={img || files[0]?.url}
                  download
                  className="absolute bottom-2 right-2 text-white text-xs underline"
                >
                  Download
                </a>
              </div>
            ) : files.length > 0 ? (
              <div className="flex flex-col items-center">
                {files.map((file) => (
                  <div key={file._id} className="flex flex-col items-center">
                    <div className="flex items-center gap-2 mb-2">
                      <div className="text-2xl">
                        {getFileIcon(file.filename)}
                      </div>
                      <div className="text-gray-200">{file.filename}</div>
                    </div>
                    <div className="w-full">
                      <a
                        href={file.url}
                        download
                        className="block bg-[#33796E] hover:bg-[#004b3b] text-white text-sm font-semibold py-2 px-4 rounded-md w-full text-center focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50 transition duration-300 ease-in-out"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            ) : poll ? (
              <div className="flex flex-col">
                <PollMessage
                  poll={poll}
                  onVote={(pollId, optionIndex) =>
                    handleVote(pollId, optionIndex)
                  }
                  sent={sent}
                />
              </div>
            ) : audio ? (
              <div className="flex flex-col items-start">
                <audio controls className="rounded-md max-w-[270px] w-300">
                  <source src={audio} type="audio/wav" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            ) : isLink ? (
              <a
                href={msg}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#53beec] hover:text-[#53beec] focus:text-[#53beec] active:text-[#53beec] text-sm underline hover:underline"
              >
                {msg}
              </a>
            ) : (
              <p
                className={`text-white text-sm ${
                  isDeleted ? "text-gray-500" : ""
                }`}
              >
                {isDeleted ? "This message was deleted" : msg}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center ml-2 -mr-2">
          <div className="flex-grow"></div>
          <div className="w-6 h-6" onClick={handleIconClick}>
            <IoIosArrowDropdownCircle className="text-[#8796a1] text-xl cursor-pointer" />
          </div>
        </div>
      </div>
      {selectedReaction && (
        <div
          className="absolute -bottom-0 left-0 text-xl"
          style={{ transform: "translateY(100%)" }}
          onClick={handleReactionManagementClick}
        >
          {selectedReaction}
        </div>
      )}
      {isDialogOpen && (
        <MessageDialog
          isSent={sent}
          onClose={handleCloseDialog}
          onEdit={handleEditMessage}
          onDelete={handleDelete}
          onPinMessage={onPinMessage}
          onForward={() => console.log("Forward message")}
          onReply={handleReply}
          messageId={msg._id}
          message={{ msg: msg?.content || "", time, sent }}
          onReact={handleReact}
          messageType={messageType}
          onPin={handlePinToggle}
          isPinned={isPinned}
        />
      )}
      {isReactionDialogOpen && (
        <ReactionDialog
          onClose={handleCloseReactionDialog}
          onSelectReaction={handleReactionSelect}
        />
      )}
      {isReactionManagementDialogOpen && (
        <ReactionManagementDialog
          reaction={selectedReaction}
          onRemoveReaction={handleRemoveReaction}
          onClose={handleCloseReactionManagementDialog}
        />
      )}
      <div className="flex justify-between ">
        <div className="mt-2 -mb-1">
          <StatusTicks status={status} />
        </div>
        <div className="absolute right-2 bottom-1 text-[#c0c6cb] text-[10px]">
          {time}
        </div>
      </div>
    </div>
  );
}

export default MessageBubble;

// import React, { useState, useEffect } from "react";
// import { IoIosArrowDropdownCircle } from "react-icons/io";
// import { IoCloseCircleSharp } from "react-icons/io5";
// import StatusTicks from "./StatusTicks";
// import MessageDialog from "./MessageDialog";
// import ReactionDialog from "./ReactionDialog";
// import ReactionManagementDialog from "./ReactionManagementDialog";
// import { imgUrl } from "../utils/services";
// import {
//   FaFilePdf,
//   FaFileWord,
//   FaFileExcel,
//   FaFilePowerpoint,
//   FaFile,
// } from "react-icons/fa";
// import PollMessage from "./PollMessage";

// function MessageBubble({
//   msg = {},
//   type,
//   time,
//   isLink,
//   img,
//   audio,
//   sent,
//   status,
//   profileImage,
//   onProfilePicClick,
//   onEditMessage,
//   onDeleteMessage,
//   onPinMessage,
//   onReplyMessage,
//   replyingTo,
//   files = [],
//   poll,
//   onUnpinMessage,
//   isPinned,
// }) {
//   console.log(`file: ${files}`);
//   const [isDialogOpen, setIsDialogOpen] = useState(false);
//   const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
//   const [editMessage, setEditMessage] = useState(msg);
//   const [isEditing, setIsEditing] = useState(false);
//   const [isReactionDialogOpen, setIsReactionDialogOpen] = useState(false);
//   const [selectedReaction, setSelectedReaction] = useState(null);
//   const [isReactionManagementDialogOpen, setIsReactionManagementDialogOpen] =
//     useState(false);

//   const handleVote = (pollIndex, optionIndex) => {
//     console.log(`Poll ${pollIndex} option ${optionIndex} selected`);
//   };

//   const handleIconClick = () => {
//     setIsDialogOpen(!isDialogOpen);
//   };

//   const handleCloseDialog = () => {
//     setIsDialogOpen(false);
//   };

//   const handleEditMessage = () => {
//     setIsEditing(true);
//     setIsDialogOpen(false);
//   };

//   const handleMessageChange = (e) => {
//     setEditMessage(e.target.value);
//   };

//   const handleMessageSubmit = (e) => {
//     if (e.key === "Enter") {
//       e.preventDefault();
//       onEditMessage(editMessage);
//       setIsEditing(false);
//     }
//   };

//   const handleReply = () => {
//     onReplyMessage({ id: msg.id, msg });
//   };

//   const handleDelete = (option) => {
//     onDeleteMessage(option);
//     setIsDialogOpen(false);
//     setSelectedReaction(null);
//   };

//   const handleProfileClick = () => {
//     setIsProfileDialogOpen(true);
//   };

//   const handleCloseProfileDialog = () => {
//     setIsProfileDialogOpen(false);
//   };

//   const handleReact = () => {
//     setIsDialogOpen(false);
//     setIsReactionDialogOpen(true);
//   };

//   const handleReactionSelect = (reaction) => {
//     setSelectedReaction(reaction);
//     setIsReactionDialogOpen(false);
//   };

//   const handleCloseReactionDialog = () => {
//     setIsReactionDialogOpen(false);
//   };

//   const handleReactionManagementClick = () => {
//     setIsReactionManagementDialogOpen(!isReactionManagementDialogOpen);
//   };

//   const handleRemoveReaction = () => {
//     setSelectedReaction(null);
//   };

//   const handleCloseReactionManagementDialog = () => {
//     setIsReactionManagementDialogOpen(false);
//   };

//   const handlePinToggle = () => {
//     if (isPinned) {
//       if (typeof onUnpinMessage === "function") {
//         onUnpinMessage(msg._id); // Pass the message's _id
//       } else {
//         console.error("onUnpinMessage function error.");
//       }
//     } else {
//       if (typeof onPinMessage === "function") {
//         onPinMessage(); // Pin logic
//       } else {
//         console.error("onPinMessage function error.");
//       }
//     }
//     setIsDialogOpen(false); // Close the dialog after pinning/unpinning
//   };

//   // Create URL for the first file
//   const fileUrl = files.length > 0 ? URL.createObjectURL(files[0]) : null;

//   useEffect(() => {
//     return () => {
//       if (fileUrl) {
//         URL.revokeObjectURL(fileUrl);
//         console.log("file:" + fileUrl);
//       }
//     };
//   }, [fileUrl]);

//   const getFileIcon = (fileName) => {
//     const extension = fileName.split(".").pop().toLowerCase();
//     switch (extension) {
//       case "pdf":
//         return <FaFilePdf className="text-red-600" />;
//       case "doc":
//       case "docx":
//         return <FaFileWord className="text-blue-600" />;
//       case "xls":
//       case "xlsx":
//         return <FaFileExcel className="text-green-600" />;
//       case "ppt":
//       case "pptx":
//         return <FaFilePowerpoint className="text-orange-600" />;
//       default:
//         return <FaFile className="text-gray-600" />;
//     }
//   };

//   const messageMargin = selectedReaction ? "mb-4" : "my-2";

//   // Safely check the type of the first file if it exists
//   const isImage =
//     img ||
//     (files.length > 0 &&
//       files[0].url &&
//       files[0].filename &&
//       files[0].url.endsWith(".jpg"));

//   const messageType = isImage
//     ? "image"
//     : files.length > 0
//     ? "file"
//     : audio
//     ? "audio"
//     : isLink
//     ? "link"
//     : poll
//     ? "poll"
//     : "text";

//   const isDeleted = msg && typeof msg === "object" && msg.isDeleted;

//   return (
//     <div
//       className={`relative flex flex-col rounded-md p-2 mb-2 ${
//         sent ? "bg-[#005c4b] ml-auto" : "bg-[#202d33] mr-auto"
//       }`}
//       style={{ width: "fit-content", minWidth: "80px", maxWidth: "100%" }}
//     >
//       <div className="relative flex flex-row items-center">
//         {!sent && (
//           <div className="relative">
//             <img
//               src={`${imgUrl}${profileImage}`}
//               alt="User Profile"
//               className="w-8 h-6 rounded-full mr-2 cursor-pointer"
//               onClick={handleProfileClick}
//             />
//             {isProfileDialogOpen && (
//               <div className="absolute top-10 left-0 bg-opacity- p-4 rounded-md shadow-md w-48 z-50">
//                 <div className="relative">
//                   <img
//                     src={`${imgUrl}${profileImage}`}
//                     alt="Profile"
//                     className="w-24 h-24 rounded-full mx-auto"
//                   />
//                   <IoCloseCircleSharp
//                     className="absolute top-1 right-1 text-red-500 text-xl cursor-pointer"
//                     onClick={handleCloseProfileDialog}
//                   />
//                 </div>
//               </div>
//             )}
//           </div>
//         )}
//         <div className="flex flex-col w-full h-full text-center">
//           <div
//             className="flex flex-col w-full h-full ml-1"
//             style={{ wordBreak: "break-word" }}
//           >
//             {isEditing ? (
//               <textarea
//                 value={editMessage}
//                 onChange={handleMessageChange}
//                 onKeyDown={handleMessageSubmit}
//                 className="w-full p-2 rounded-md text-black"
//                 autoFocus
//               />
//             ) : isImage ? (
//               <div className="relative w-100">
//                 <img
//                   src={img || fileUrl}
//                   alt="message_content"
//                   className="rounded-md max-w-[270px] w-100"
//                 />
//                 <a
//                   href={img || fileUrl}
//                   download
//                   className="absolute bottom-2 right-2 text-white text-xs underline"
//                 >
//                   Download
//                 </a>
//               </div>
//             ) : files.length > 0 ? (
//               <div className="flex flex-col items-center">
//                 {files.map((file) => (
//                   <div key={file._id} className="flex items-center">
//                     {getFileIcon(file.filename)}
//                     <a
//                       href={file.url}
//                       download
//                       className="ml-2 text-[#53beec] text-sm underline"
//                     >
//                       {file.filename}
//                     </a>
//                   </div>
//                 ))}
//               </div>
//             ) : poll ? (
//               <div className="flex flex-col">
//                 <PollMessage
//                   poll={poll}
//                   onVote={(pollId, optionIndex) =>
//                     handleVote(pollId, optionIndex)
//                   }
//                   sent={sent}
//                 />
//               </div>
//             ) : audio ? (
//               <div className="flex flex-col items-start">
//                 <audio controls className="rounded-md max-w-[270px] w-300">
//                   <source src={audio} type="audio/wav" />
//                   Your browser does not support the audio element.
//                 </audio>
//               </div>
//             ) : isLink ? (
//               <a
//                 href={msg}
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="text-[#53beec] hover:text-[#53beec] focus:text-[#53beec] active:text-[#53beec] text-sm underline hover:underline"
//               >
//                 {msg}
//               </a>
//             ) : (
//               <p
//                 className={`text-white text-sm ${
//                   isDeleted ? "text-gray-500" : ""
//                 }`}
//               >
//                 {isDeleted ? "This message was deleted" : msg}
//               </p>
//             )}
//           </div>
//         </div>

//         <div className="flex flex-row justify-between items-center ml-2 -mr-2">
//           <div className="flex-grow"></div>
//           <div className="w-6 h-6" onClick={handleIconClick}>
//             <IoIosArrowDropdownCircle className="text-[#8796a1] text-xl cursor-pointer" />
//           </div>
//         </div>
//       </div>
//       {selectedReaction && (
//         <div
//           className="absolute -bottom-0 left-0 text-xl"
//           style={{ transform: "translateY(100%)" }}
//           onClick={handleReactionManagementClick}
//         >
//           {selectedReaction}
//         </div>
//       )}
//       {isDialogOpen && (
//         <MessageDialog
//           isSent={sent}
//           onClose={handleCloseDialog}
//           onEdit={handleEditMessage}
//           onDelete={handleDelete}
//           onPinMessage={onPinMessage}
//           onForward={() => console.log("Forward message")}
//           onReply={handleReply}
//           messageId={msg._id}
//           message={{ msg: msg?.content || "", time, sent }}
//           onReact={handleReact}
//           messageType={messageType}
//           onPin={handlePinToggle}
//           isPinned={isPinned}
//         />
//       )}
//       {isReactionDialogOpen && (
//         <ReactionDialog
//           onClose={handleCloseReactionDialog}
//           onSelectReaction={handleReactionSelect}
//         />
//       )}
//       {isReactionManagementDialogOpen && (
//         <ReactionManagementDialog
//           reaction={selectedReaction}
//           onRemoveReaction={handleRemoveReaction}
//           onClose={handleCloseReactionManagementDialog}
//         />
//       )}
//       <div className="flex justify-between ">
//         <div className="mt-2 -mb-1">
//           <StatusTicks status={status} />
//         </div>
//         <div className="absolute right-2 bottom-1 text-[#c0c6cb] text-[10px]">
//           {time}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MessageBubble;

// // import React, { useState, useEffect } from "react";
// // import { IoIosArrowDropdownCircle } from "react-icons/io";
// // import { IoCloseCircleSharp } from "react-icons/io5";
// // import StatusTicks from "./StatusTicks";
// // import MessageDialog from "./MessageDialog";
// // import ReactionDialog from "./ReactionDialog";
// // import ReactionManagementDialog from "./ReactionManagementDialog";
// // import { imgUrl } from "../utils/services";
// // import {
// //   FaFilePdf,
// //   FaFileWord,
// //   FaFileExcel,
// //   FaFilePowerpoint,
// //   FaFile,
// // } from "react-icons/fa";
// // import PollMessage from "./PollMessage";

// // function MessageBubble({
// //   msg = {},
// //   type,
// //   time,
// //   isLink,
// //   img,
// //   audio,
// //   sent,
// //   status,
// //   profileImage,
// //   onProfilePicClick,
// //   onEditMessage,
// //   onDeleteMessage,
// //   onPinMessage,
// //   onReplyMessage,
// //   replyingTo,
// //   files = [],
// //   poll,
// //   onUnpinMessage,
// //   isPinned,
// // }) {
// //   console.log(`file: ${files}`);
// //   const [isDialogOpen, setIsDialogOpen] = useState(false);
// //   const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
// //   const [editMessage, setEditMessage] = useState(msg);
// //   const [isEditing, setIsEditing] = useState(false);
// //   const [isReactionDialogOpen, setIsReactionDialogOpen] = useState(false);
// //   const [selectedReaction, setSelectedReaction] = useState(null);
// //   const [isReactionManagementDialogOpen, setIsReactionManagementDialogOpen] =
// //     useState(false);

// //   const handleVote = (pollIndex, optionIndex) => {
// //     console.log(`Poll ${pollIndex} option ${optionIndex} selected`);
// //   };

// //   const handleIconClick = () => {
// //     setIsDialogOpen(!isDialogOpen);
// //   };

// //   const handleCloseDialog = () => {
// //     setIsDialogOpen(false);
// //   };

// //   const handleEditMessage = () => {
// //     setIsEditing(true);
// //     setIsDialogOpen(false);
// //   };

// //   const handleMessageChange = (e) => {
// //     setEditMessage(e.target.value);
// //   };

// //   const handleMessageSubmit = (e) => {
// //     if (e.key === "Enter") {
// //       e.preventDefault();
// //       onEditMessage(editMessage);
// //       setIsEditing(false);
// //     }
// //   };

// //   const handleReply = () => {
// //     onReplyMessage({ id: msg.id, msg });
// //   };

// //   const handleDelete = (option) => {
// //     onDeleteMessage(option);
// //     setIsDialogOpen(false);
// //     setSelectedReaction(null);
// //   };

// //   const handleProfileClick = () => {
// //     setIsProfileDialogOpen(true);
// //   };

// //   const handleCloseProfileDialog = () => {
// //     setIsProfileDialogOpen(false);
// //   };

// //   const handleReact = () => {
// //     setIsDialogOpen(false);
// //     setIsReactionDialogOpen(true);
// //   };

// //   const handleReactionSelect = (reaction) => {
// //     setSelectedReaction(reaction);
// //     setIsReactionDialogOpen(false);
// //   };

// //   const handleCloseReactionDialog = () => {
// //     setIsReactionDialogOpen(false);
// //   };

// //   const handleReactionManagementClick = () => {
// //     setIsReactionManagementDialogOpen(!isReactionManagementDialogOpen);
// //   };

// //   const handleRemoveReaction = () => {
// //     setSelectedReaction(null);
// //   };

// //   const handleCloseReactionManagementDialog = () => {
// //     setIsReactionManagementDialogOpen(false);
// //   };

// //   const handlePinToggle = () => {
// //     if (isPinned) {
// //       if (typeof onUnpinMessage === "function") {
// //         onUnpinMessage(msg._id); // Pass the message's _id
// //       } else {
// //         console.error("onUnpinMessage function error.");
// //       }
// //     } else {
// //       if (typeof onPinMessage === "function") {
// //         onPinMessage(); // Pin logic
// //       } else {
// //         console.error("onPinMessage function error.");
// //       }
// //     }
// //     setIsDialogOpen(false); // Close the dialog after pinning/unpinning
// //   };
// //   const fileUrl = files.length > 0 ? URL.createObjectURL(files[0]) : null;
// //   useEffect(() => {
// //     return () => {
// //       if (fileUrl) {
// //         URL.revokeObjectURL(fileUrl);
// //         console.log("file:" + fileUrl);
// //       }
// //     };
// //   }, [fileUrl]);

// //   const getFileIcon = (fileName) => {
// //     const extension = fileName.split(".").pop().toLowerCase();
// //     switch (extension) {
// //       case "pdf":
// //         return <FaFilePdf className="text-red-600" />;
// //       case "doc":
// //       case "docx":
// //         return <FaFileWord className="text-blue-600" />;
// //       case "xls":
// //       case "xlsx":
// //         return <FaFileExcel className="text-green-600" />;
// //       case "ppt":
// //       case "pptx":
// //         return <FaFilePowerpoint className="text-orange-600" />;
// //       default:
// //         return <FaFile className="text-gray-600" />;
// //     }
// //   };

// //   const messageMargin = selectedReaction ? "mb-4" : "my-2";
// //   const isImage = img || (files && files.type.startsWith("image/"));
// //   const messageType = isImage
// //     ? "image"
// //     : files
// //     ? "file"
// //     : audio
// //     ? "audio"
// //     : isLink
// //     ? "link"
// //     : poll
// //     ? "poll"
// //     : "text";

// //   const isDeleted = msg && typeof msg === "object" && msg.isDeleted;

// //   return (
// //     <div
// //       className={`relative flex flex-col rounded-md p-2 mb-2 ${
// //         sent ? "bg-[#005c4b] ml-auto" : "bg-[#202d33] mr-auto"
// //       }`}
// //       style={{ width: "fit-content", minWidth: "80px", maxWidth: "100%" }}
// //     >
// //       <div className="relative flex flex-row items-center">
// //         {!sent && (
// //           <div className="relative">
// //             <img
// //               src={`${imgUrl}${profileImage}`}
// //               alt="User Profile"
// //               className="w-8 h-6 rounded-full mr-2 cursor-pointer"
// //               onClick={handleProfileClick}
// //             />
// //             {isProfileDialogOpen && (
// //               <div className="absolute top-10 left-0 bg-opacity- p-4 rounded-md shadow-md w-48 z-50">
// //                 <div className="relative">
// //                   <img
// //                     src={`${imgUrl}${profileImage}`}
// //                     alt="Profile"
// //                     className="w-24 h-24 rounded-full mx-auto"
// //                   />
// //                   <IoCloseCircleSharp
// //                     className="absolute top-1 right-1 text-red-500 text-xl cursor-pointer"
// //                     onClick={handleCloseProfileDialog}
// //                   />
// //                 </div>
// //               </div>
// //             )}
// //           </div>
// //         )}
// //         <div className="flex flex-col w-full h-full text-center">
// //           <div
// //             className="flex flex-col w-full h-full ml-1"
// //             style={{ wordBreak: "break-word" }}
// //           >
// //             {isEditing ? (
// //               <textarea
// //                 value={editMessage}
// //                 onChange={handleMessageChange}
// //                 onKeyDown={handleMessageSubmit}
// //                 className="w-full p-2 rounded-md text-black"
// //                 autoFocus
// //               />
// //             ) : isImage ? (
// //               <div className="relative w-100">
// //                 <img
// //                   src={img || fileUrl}
// //                   alt="message_content"
// //                   className="rounded-md max-w-[270px] w-100"
// //                 />
// //                 <a
// //                   href={img || fileUrl}
// //                   download
// //                   className="absolute bottom-2 right-2 text-white text-xs underline"
// //                 >
// //                   Download
// //                 </a>
// //               </div>
// //             ) : files ? (
// //               <div className="flex flex-col items-center">
// //                 <div className="flex items-center">
// //                   {getFileIcon(files.name)}
// //                   <span className="text-white text-sm ml-2">{files.name}</span>
// //                   <a
// //                     href={fileUrl}
// //                     download
// //                     className="ml-2 text-[#53beec] text-sm underline"
// //                   >
// //                     Download
// //                   </a>
// //                 </div>
// //               </div>
// //             ) : poll ? (
// //               <div className="flex flex-col">
// //                 <PollMessage
// //                   poll={poll}
// //                   onVote={(pollId, optionIndex) =>
// //                     handleVote(pollId, optionIndex)
// //                   }
// //                   sent={sent}
// //                 />
// //               </div>
// //             ) : audio ? (
// //               <div className="flex flex-col items-start">
// //                 <audio controls className="rounded-md max-w-[270px] w-300">
// //                   <source src={audio} type="audio/wav" />
// //                   Your browser does not support the audio element.
// //                 </audio>
// //               </div>
// //             ) : isLink ? (
// //               <a
// //                 href={msg}
// //                 target="_blank"
// //                 rel="noopener noreferrer"
// //                 className="text-[#53beec] hover:text-[#53beec] focus:text-[#53beec] active:text-[#53beec] text-sm underline hover:underline"
// //               >
// //                 {msg}
// //               </a>
// //             ) : (
// //               <p
// //                 className={`text-white text-sm ${
// //                   isDeleted ? "text-gray-500" : ""
// //                 }`}
// //               >
// //                 {isDeleted ? "This message was deleted" : msg}
// //               </p>
// //             )}
// //           </div>
// //         </div>
// //         <div className="flex flex-row justify-between items-center ml-2 -mr-2">
// //           <div className="flex-grow"></div>
// //           <div className="w-6 h-6" onClick={handleIconClick}>
// //             <IoIosArrowDropdownCircle className="text-[#8796a1] text-xl cursor-pointer" />
// //           </div>
// //         </div>
// //       </div>
// //       {selectedReaction && (
// //         <div
// //           className="absolute -bottom-0 left-0 text-xl"
// //           style={{ transform: "translateY(100%)" }}
// //           onClick={handleReactionManagementClick}
// //         >
// //           {selectedReaction}
// //         </div>
// //       )}
// //       {isDialogOpen && (
// //         <MessageDialog
// //           isSent={sent}
// //           onClose={handleCloseDialog}
// //           onEdit={handleEditMessage}
// //           onDelete={handleDelete}
// //           onPinMessage={onPinMessage}
// //           onForward={() => console.log("Forward message")}
// //           onReply={handleReply}
// //           messageId={msg._id}
// //           message={{ msg: msg?.content || "", time, sent }}
// //           onReact={handleReact}
// //           messageType={messageType}
// //           onPin={handlePinToggle}
// //           isPinned={isPinned}
// //         />
// //       )}
// //       {isReactionDialogOpen && (
// //         <ReactionDialog
// //           onClose={handleCloseReactionDialog}
// //           onSelectReaction={handleReactionSelect}
// //         />
// //       )}
// //       {isReactionManagementDialogOpen && (
// //         <ReactionManagementDialog
// //           reaction={selectedReaction}
// //           onRemoveReaction={handleRemoveReaction}
// //           onClose={handleCloseReactionManagementDialog}
// //         />
// //       )}
// //       <div className="flex justify-between ">
// //         <div className="mt-2 -mb-1">
// //           <StatusTicks status={status} />
// //         </div>
// //         <div className="absolute right-2 bottom-1 text-[#c0c6cb] text-[10px]">
// //           {time}
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

// // export default MessageBubble;

// // // import React, { useState, useEffect } from "react";
// // // import { IoIosArrowDropdownCircle } from "react-icons/io";
// // // import { IoCloseCircleSharp } from "react-icons/io5";
// // // import StatusTicks from "./StatusTicks";
// // // import MessageDialog from "./MessageDialog";
// // // import ReactionDialog from "./ReactionDialog";
// // // import ReactionManagementDialog from "./ReactionManagementDialog";
// // // import { imgUrl } from "../utils/services";
// // // import {
// // //   FaFilePdf,
// // //   FaFileWord,
// // //   FaFileExcel,
// // //   FaFilePowerpoint,
// // //   FaFile,
// // // } from "react-icons/fa";
// // // import PollMessage from "./PollMessage";

// // // function MessageBubble({
// // //   msg = {},
// // //   type,
// // //   time,
// // //   isLink,
// // //   img,
// // //   audio,
// // //   sent,
// // //   status,
// // //   profileImage,
// // //   onProfilePicClick,
// // //   onEditMessage,
// // //   onDeleteMessage,
// // //   onPinMessage,
// // //   onReplyMessage,
// // //   replyingTo,
// // //   file,
// // //   poll,
// // //   onUnpinMessage,
// // //   isPinned,
// // // }) {
// // //   console.log(`file: ${file}`);
// // //   const [isDialogOpen, setIsDialogOpen] = useState(false);
// // //   const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
// // //   const [editMessage, setEditMessage] = useState(msg);
// // //   const [isEditing, setIsEditing] = useState(false);
// // //   const [isReactionDialogOpen, setIsReactionDialogOpen] = useState(false);
// // //   const [isReactionManagementDialogOpen, setIsReactionManagementDialogOpen] =
// // //     useState(false);
// // //   const [selectedReaction, setSelectedReaction] = useState(null);

// // //   const handleVote = (pollIndex, optionIndex) => {
// // //     console.log(`Poll ${pollIndex} option ${optionIndex} selected`);
// // //   };

// // //   const handleIconClick = () => {
// // //     setIsDialogOpen(!isDialogOpen);
// // //   };

// // //   const handleCloseDialog = () => {
// // //     setIsDialogOpen(false);
// // //   };

// // //   const handleEditMessage = () => {
// // //     setIsEditing(true);
// // //     setIsDialogOpen(false);
// // //   };

// // //   const handleMessageChange = (e) => {
// // //     setEditMessage(e.target.value);
// // //   };

// // //   const handleMessageSubmit = (e) => {
// // //     if (e.key === "Enter") {
// // //       e.preventDefault();
// // //       onEditMessage(editMessage);
// // //       setIsEditing(false);
// // //     }
// // //   };

// // //   const handleReply = () => {
// // //     onReplyMessage({ id: msg.id, msg });
// // //   };

// // //   const handleDelete = (option) => {
// // //     onDeleteMessage(option);
// // //     setIsDialogOpen(false);
// // //     setSelectedReaction(null);
// // //   };

// // //   const handleProfileClick = () => {
// // //     setIsProfileDialogOpen(true);
// // //   };

// // //   const handleCloseProfileDialog = () => {
// // //     setIsProfileDialogOpen(false);
// // //   };

// // //   const handleReact = () => {
// // //     setIsDialogOpen(false);
// // //     setIsReactionDialogOpen(true);
// // //   };

// // //   const handleReactionSelect = (reaction) => {
// // //     setSelectedReaction(reaction);
// // //     setIsReactionDialogOpen(false);
// // //   };

// // //   const handleCloseReactionDialog = () => {
// // //     setIsReactionDialogOpen(false);
// // //   };

// // //   const handleReactionManagementClick = () => {
// // //     setIsReactionManagementDialogOpen(!isReactionManagementDialogOpen);
// // //   };

// // //   const handleRemoveReaction = () => {
// // //     setSelectedReaction(null);
// // //   };

// // //   const handleCloseReactionManagementDialog = () => {
// // //     setIsReactionManagementDialogOpen(false);
// // //   };

// // //   const handlePinToggle = () => {
// // //     if (isPinned) {
// // //       if (typeof onUnpinMessage === "function") {
// // //         onUnpinMessage(msg._id); // Pass the message's _id
// // //       } else {
// // //         console.error("onUnpinMessage function error.");
// // //       }
// // //     } else {
// // //       if (typeof onPinMessage === "function") {
// // //         onPinMessage(); // Pin logic
// // //       } else {
// // //         console.error("onPinMessage function error.");
// // //       }
// // //     }
// // //     setIsDialogOpen(false); // Close the dialog after pinning/unpinning
// // //   };
// // //   const fileUrl = file ? URL.createObjectURL(file) : null;
// // //   useEffect(() => {
// // //     return () => {
// // //       if (fileUrl) {
// // //         URL.revokeObjectURL(fileUrl);
// // //         console.log("file:" + fileUrl);
// // //       }
// // //     };
// // //   }, [fileUrl]);

// // //   const getFileIcon = (fileName) => {
// // //     const extension = fileName.split(".").pop().toLowerCase();
// // //     switch (extension) {
// // //       case "pdf":
// // //         return <FaFilePdf className="text-red-600" />;
// // //       case "doc":
// // //       case "docx":
// // //         return <FaFileWord className="text-blue-600" />;
// // //       case "xls":
// // //       case "xlsx":
// // //         return <FaFileExcel className="text-green-600" />;
// // //       case "ppt":
// // //       case "pptx":
// // //         return <FaFilePowerpoint className="text-orange-600" />;
// // //       default:
// // //         return <FaFile className="text-gray-600" />;
// // //     }
// // //   };

// // //   const messageMargin = selectedReaction ? "mb-4" : "my-2";
// // //   const isImage = img || (file && file.type.startsWith("image/"));
// // //   const messageType = isImage
// // //     ? "image"
// // //     : file
// // //     ? "file"
// // //     : audio
// // //     ? "audio"
// // //     : isLink
// // //     ? "link"
// // //     : poll
// // //     ? "poll"
// // //     : "text";

// // //   const isDeleted = msg && typeof msg === "object" && msg.isDeleted;

// // //   return (
// // //     <div
// // //       className={`relative flex flex-col rounded-md p-2 mb-2 ${
// // //         sent ? "bg-[#005c4b] ml-auto" : "bg-[#202d33] mr-auto"
// // //       }`}
// // //       style={{ width: "fit-content", minWidth: "80px", maxWidth: "100%" }}
// // //     >
// // //       <div className="relative flex flex-row items-center">
// // //         {!sent && (
// // //           <div className="relative">
// // //             <img
// // //               src={`${imgUrl}${profileImage}`}
// // //               alt="User Profile"
// // //               className="w-8 h-6 rounded-full mr-2 cursor-pointer"
// // //               onClick={handleProfileClick}
// // //             />
// // //             {isProfileDialogOpen && (
// // //               <div className="absolute top-10 left-0 bg-opacity- p-4 rounded-md shadow-md w-48 z-50">
// // //                 <div className="relative">
// // //                   <img
// // //                     src={`${imgUrl}${profileImage}`}
// // //                     alt="Profile"
// // //                     className="w-24 h-24 rounded-full mx-auto"
// // //                   />
// // //                   <IoCloseCircleSharp
// // //                     className="absolute top-1 right-1 text-red-500 text-xl cursor-pointer"
// // //                     onClick={handleCloseProfileDialog}
// // //                   />
// // //                 </div>
// // //               </div>
// // //             )}
// // //           </div>
// // //         )}
// // //         <div className="flex flex-col w-full h-full text-center">
// // //           <div
// // //             className="flex flex-col w-full h-full ml-1"
// // //             style={{ wordBreak: "break-word" }}
// // //           >
// // //             {isEditing ? (
// // //               <textarea
// // //                 value={editMessage}
// // //                 onChange={handleMessageChange}
// // //                 onKeyDown={handleMessageSubmit}
// // //                 className="w-full p-2 rounded-md text-black"
// // //                 autoFocus
// // //               />
// // //             ) : isImage ? (
// // //               <div className="relative w-100">
// // //                 <img
// // //                   src={img || fileUrl}
// // //                   alt="message_content"
// // //                   className="rounded-md max-w-[270px] w-100"
// // //                 />
// // //                 <a
// // //                   href={img || fileUrl}
// // //                   download
// // //                   className="absolute bottom-2 right-2 text-white text-xs underline"
// // //                 >
// // //                   Download
// // //                 </a>
// // //               </div>
// // //             ) : file ? (
// // //               <div className="flex flex-col items-center">
// // //                 <div className="flex items-center">
// // //                   {getFileIcon(file.name)}
// // //                   <span className="text-white text-sm ml-2">{file.name}</span>
// // //                   <a
// // //                     href={fileUrl}
// // //                     download
// // //                     className="ml-2 text-[#53beec] text-sm underline"
// // //                   >
// // //                     Download
// // //                   </a>
// // //                 </div>
// // //               </div>
// // //             ) : poll ? (
// // //               <div className="flex flex-col">
// // //                 <PollMessage
// // //                   poll={poll}
// // //                   onVote={(pollId, optionIndex) =>
// // //                     handleVote(pollId, optionIndex)
// // //                   }
// // //                   sent={sent}
// // //                 />
// // //               </div>
// // //             ) : audio ? (
// // //               <div className="flex flex-col items-start">
// // //                 <audio controls className="rounded-md max-w-[270px] w-300">
// // //                   <source src={audio} type="audio/wav" />
// // //                   Your browser does not support the audio element.
// // //                 </audio>
// // //               </div>
// // //             ) : isLink ? (
// // //               <a
// // //                 href={msg}
// // //                 target="_blank"
// // //                 rel="noopener noreferrer"
// // //                 className="text-[#53beec] hover:text-[#53beec] focus:text-[#53beec] active:text-[#53beec] text-sm underline hover:underline"
// // //               >
// // //                 {msg}
// // //               </a>
// // //             ) : (
// // //               <p
// // //                 className={`text-white text-sm ${
// // //                   isDeleted ? "text-gray-500" : ""
// // //                 }`}
// // //               >
// // //                 {isDeleted ? "This message was deleted" : msg}
// // //               </p>
// // //             )}
// // //           </div>
// // //         </div>
// // //         <div className="flex flex-row justify-between items-center ml-2 -mr-2">
// // //           <div className="flex-grow"></div>
// // //           <div className="w-6 h-6" onClick={handleIconClick}>
// // //             <IoIosArrowDropdownCircle className="text-[#8796a1] text-xl cursor-pointer" />
// // //           </div>
// // //         </div>
// // //       </div>
// // //       {selectedReaction && (
// // //         <div
// // //           className="absolute -bottom-0 left-0 text-xl"
// // //           style={{ transform: "translateY(100%)" }}
// // //           onClick={handleReactionManagementClick}
// // //         >
// // //           {selectedReaction}
// // //         </div>
// // //       )}
// // //       {isDialogOpen && (
// // //         <MessageDialog
// // //           isSent={sent}
// // //           onClose={handleCloseDialog}
// // //           onEdit={handleEditMessage}
// // //           onDelete={handleDelete}
// // //           onPinMessage={onPinMessage}
// // //           onForward={() => console.log("Forward message")}
// // //           onReply={handleReply}
// // //           messageId={msg._id}
// // //           message={{ msg: msg?.content || "", time, sent }}
// // //           onReact={handleReact}
// // //           messageType={messageType}
// // //           onPin={handlePinToggle}
// // //           isPinned={isPinned}
// // //         />
// // //       )}
// // //       {isReactionDialogOpen && (
// // //         <ReactionDialog
// // //           onClose={handleCloseReactionDialog}
// // //           onSelectReaction={handleReactionSelect}
// // //         />
// // //       )}
// // //       {isReactionManagementDialogOpen && (
// // //         <ReactionManagementDialog
// // //           reaction={selectedReaction}
// // //           onRemoveReaction={handleRemoveReaction}
// // //           onClose={handleCloseReactionManagementDialog}
// // //         />
// // //       )}
// // //       <div className="flex justify-between ">
// // //         <div className="mt-2 -mb-1">
// // //           <StatusTicks status={status} />
// // //         </div>
// // //         <div className="absolute right-2 bottom-1 text-[#c0c6cb] text-[10px]">
// // //           {time}
// // //         </div>
// // //       </div>
// // //     </div>
// // //   );
// // // }

// // // export default MessageBubble;
