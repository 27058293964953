import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import ResetPassword from "../components/LoginPage/ResetPassword";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";


const Login = () => {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated, setUser } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://52.66.245.210:5000/api/auth/login', { mobile, password });
      console.log('Login response:', response.data);
      localStorage.setItem('token', response.data.token);
      setIsAuthenticated(true);
      setUser(response.data);
      navigate('/chatweb');
    } catch (error) {
      console.log('Login error:', error);
      setMessage(error.response?.data?.message || "An error occurred");
    }
  };

  const handleForgotPassword = () => {
    setShowResetPassword(true);
  };

  const handleBackToLogin = () => {
    setShowResetPassword(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="flex justify-center items-center min-h-screen bg-[#0e1b28] bg-[url('assets/images/lBG.png')] bg-cover bg-center overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-800 scrollbar-track-gray-900 backdrop-blur-md"
      style={{ padding: '12px 10%' }}
    >
      <div className="absolute inset-0 bg-gray-500 opacity-30"></div>

      {showResetPassword ? (
        <ResetPassword onBackToLogin={handleBackToLogin} />
      ) : (
        <form onSubmit={handleLogin} className="relative bg-gray-900 bg-animated bg-opacity-60 backdrop-blur-md p-8 rounded-lg shadow-lg max-w-sm w-full">
          <div className="absolute inset-0 bg-gray-800 bg-opacity-60 rounded-lg"></div>

          <div className="relative z-10">
            <h2 className="text-4xl font-semibold mb-6 text-center bg-gradient-to-r from-green-400 to-emerald-500 text-transparent bg-clip-text transform transition-transform duration-300 ease-out hover:scale-105">
              Login
            </h2>
            <div className="relative mb-6">
              <input
                type="text"
                placeholder="Mobile Number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
                className="w-full p-6 mb-2 bg-gray-800 bg-opacity-50 border border-gray-700 rounded focus:outline-none focus:border-green-500 focus:ring-2 focus:ring-green-500 text-white placeholder-gray-400 pl-10"
              />
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400 -mt-3">
                <FaPhone size={20} />
              </span>
            </div>

            <div className="relative mb-6">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full p-6 mb-2 bg-gray-800 bg-opacity-50 border border-gray-700 rounded focus:outline-none focus:border-green-500 focus:ring-2 focus:ring-green-500 text-white placeholder-gray-400 pl-10"
              />
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400 -mt-3">
                <FaLock size={20} />
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-6 cursor-pointer" onClick={togglePasswordVisibility}>
                {showPassword ? <FaEye size={20} color="white" /> : <FaEyeSlash size={20} color="white" />} {/* Eye icon */}
              </span>
            </div>
            <button type="submit" className="w-full bg-gradient-to-r from-green-500 to-emerald-600 text-white py-3 rounded-lg font-bold hover:from-green-600 hover:to-emerald-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-900 transition duration-200">
              Login
            </button>
            {message && <p className="mt-4 text-center text-red-500">{message}</p>}
            <div className="mt-4 flex justify-between text-center">
              <div className="mt-4 text-center">
                <div className="flex flex-col space-y-2">
                  <div className="flex items-start space-x-1">
                    <p className="text-gray-100">Don't have an account?</p>
                    <button onClick={() => navigate('/signup')} className="text-green-400 hover:underline">
                      Signup
                    </button>
                  </div>

                  <div className="flex items-start">
                    <button
                      type="button"
                      onClick={handleForgotPassword}
                      className="text-red-300 hover:underline"
                    >
                      Forgot Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

    </div>
  );
};

export default Login;
