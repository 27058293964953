import React, { useState, useEffect } from "react";

const emojiCategories = {
  Smileys: [
    "😊",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
    "😊",
    "😂",
    "❤️",
    "😍",
    "😢",
    "😡",
  ],
  Animals: ["🐶", "🐱", "🐭", "🐹", "🐰", "🦊"],
  Food: ["🍎", "🍊", "🍇", "🍉", "🍌", "🍒"],
  Travel: ["✈️", "🚗", "🚂", "🚀", "🚁", "🚤"],
  Smileysd: ["😊", "😂", "❤️", "😍", "😢", "😡"],
  Animalsd: ["🐶", "🐱", "🐭", "🐹", "🐰", "🦊"],
  Foodd: ["🍎", "🍊", "🍇", "🍉", "🍌", "🍒"],
  Traveldg: ["✈️", "🚗", "🚂", "🚀", "🚁", "🚤"],
  Smileysdg: ["😊", "😂", "❤️", "😍", "😢", "😡"],
  Animalsdg: ["🐶", "🐱", "🐭", "🐹", "🐰", "🦊"],
  Fooddg: ["🍎", "🍊", "🍇", "🍉", "🍌", "🍒"],
  Traveldg: ["✈️", "🚗", "🚂", "🚀", "🚁", "🚤"],
};

const EmojiPicker = ({ onClose, onEmojiSelect }) => {
  const [activeTab, setActiveTab] = useState("Smileys");
  const [selectedEmojis, setSelectedEmojis] = useState("");

  useEffect(() => {
    const lastUsedTab = localStorage.getItem("lastUsedTab");
    if (lastUsedTab) {
      setActiveTab(lastUsedTab);
    }
  }, []);

  const handleEmojiSelect = (emoji) => {
    setSelectedEmojis((prev) => prev + emoji);
    onEmojiSelect(emoji);
  };

  const handleTabClick = (category) => {
    setActiveTab(category);
    localStorage.setItem("lastUsedTab", category);
  };

  return (
    <div className="absolute bottom-0 left-0 right-0 bg-[#1c2730] text-white shadow-lg p-4 max-h-full overflow-hidden">
      <h3 className="text-xl font-semibold mb-4">Choose an Emoji</h3>

      <div className="flex space-x-4 mb-4 overflow-x-auto scrollbar-hide">
        {Object.keys(emojiCategories).map((category) => (
          <span
            key={category}
            className={`text-sm cursor-pointer ${
              activeTab === category ? "text-blue-500" : "text-gray-400"
            }`}
            onClick={() => handleTabClick(category)}
          >
            {category}
          </span>
        ))}
      </div>

      <div className="flex flex-wrap overflow-y-auto scrollbar-thin scrollbar-track-[#1c2730] scrollbar-thumb-slate-400 max-h-48 w-full">
        {emojiCategories[activeTab].map((emoji, index) => (
          <span
            key={index}
            className="text-2xl cursor-pointer p-2 hover:bg-gray-700 rounded"
            onClick={() => handleEmojiSelect(emoji)}
          >
            {emoji}
          </span>
        ))}
      </div>

      <div className="mt-4 bg-gray-800 p-2 rounded-lg w-full text-white">
        {selectedEmojis}
      </div>

      <button
        className="mt-4 bg-red-500 p-2 rounded-lg w-full text-white"
        onClick={onClose}
      >
        Close
      </button>
    </div>
  );
};

export default EmojiPicker;
