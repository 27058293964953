import React, { useEffect } from "react";
import SearchResults from "./SearchResults";
import { ManageUsers } from "../components/ManageUsers";

function AllContact({ user, showAllUsers, setShowAllUsers, addNewChat }) {
  const { showSelection, allUsers, handleUserAdd } = ManageUsers(user);

  const sortedUsers = [...allUsers].sort((a, b) => {
    const nameA = a.username ? a.username.toLowerCase() : "";
    const nameB = b.username ? b.username.toLowerCase() : "";
    return nameA.localeCompare(nameB);
  });

  useEffect(() => {
    console.log("showSelection:", showSelection);
    console.log("showAllUsers:", showAllUsers);
    console.log("allUsers:", allUsers);
  }, [showSelection, showAllUsers, allUsers]);

  return (
    <div className="flex h-screen">
      {showSelection && (
        <div
          className={`flex-1 h-full bg-[#111a21] ${
            showAllUsers ? "flex" : "hidden"
          }`}
        >
          <div className="bg-[#111a21] w-full flex-1 flex flex-col items-center max-h-screen">
            <SearchResults
              onClick={() => setShowAllUsers(false)}
              users={sortedUsers}
              onUserAdd={handleUserAdd}
              user={user}
              addNewChat={addNewChat}
            />
          </div>
        </div>
      )}
      {!showSelection && (
        <div className="text-white">
          <p>Nothing to show, check your conditions.</p>
        </div>
      )}
    </div>
  );
}

export default AllContact;
