import React, { useState, useRef } from "react";
import { MdEdit, MdAccountCircle, MdCheck } from "react-icons/md";
import DropDownMenu from "./Common/DropDownMenu";

const UserProfile = ({
  isOpen,
  onClose,
  profilePic,
  onUpdateProfilePicture,
  user,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [photoView, setPhotoView] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingAbout, setIsEditingAbout] = useState(false);
  const [name, setName] = useState(user.username);
  const [about, setAbout] = useState(user.about || "");
  const fileInputRef = useRef(null);

  if (!isOpen) return null;

  const handleUserProfile = (event) => {
    setDropdownPosition({
      top: event.clientY,
      left: event.clientX,
    });
    setDropdownVisible(!dropdownVisible);
  };

  const handleUpdatePicture = () => {
    fileInputRef.current.click();
  };

  const handleRemovePic = () => {
    onUpdateProfilePicture(null);
  };

  const handlePhotoView = () => {
    setPhotoView(true);
  };

  const closePhotoView = () => {
    setPhotoView(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onUpdateProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNameEdit = () => {
    setIsEditingName(true);
  };

  const handleNameSave = () => {
    setIsEditingName(false);
  };

  const handleAboutEdit = () => {
    setIsEditingAbout(true);
  };

  const handleAboutSave = () => {
    setIsEditingAbout(false);
  };

  const dropdownOptions = [
    { label: "View photo", onClick: handlePhotoView },
    { label: "Upload photo", onClick: handleUpdatePicture },
    { label: "Remove photo", onClick: handleRemovePic },
  ];

  return (
    <div className="fixed inset-0 z-50 flex justify-start">
      {photoView ? (
        <div className="fixed inset-0 bg-slate-950 bg-opacity-90 flex justify-center items-center z-50">
          {profilePic ? (
            <img
              src={profilePic}
              alt="Profile"
              className="max-w-[90%] max-h-[90%] rounded-lg"
            />
          ) : (
            <h2 className="text-gray-500">No photo available</h2>
          )}
          <button
            className="absolute top-2 right-2 text-4xl text-white"
            onClick={closePhotoView}
          >
            &times;
          </button>
        </div>
      ) : (
        <div className="w-[420px] h-screen bg-black bg-opacity-50">
          <div className="flex flex-col justify-center bg-[#111a21] bg-opacity-95 h-full p-4 text-white relative">
            <button
              className="absolute top-2 right-2 text-xl"
              onClick={onClose}
            >
              &times;
            </button>
            <div className="flex flex-col -mt-6 font-light">
              {profilePic ? (
                <img
                  src={profilePic}
                  alt="profile-picture"
                  onClick={handleUserProfile}
                  className="rounded-full w-56 h-56 mb-4 self-center cursor-pointer"
                />
              ) : (
                <MdAccountCircle
                  className="text-[#8796a1] hover:text-gray-500 w-56 h-56 mb-4 self-center cursor-pointer"
                  onClick={handleUserProfile}
                />
              )}

              {dropdownVisible && (
                <DropDownMenu
                  options={dropdownOptions}
                  position={dropdownPosition}
                  onClose={() => setDropdownVisible(false)}
                />
              )}

              <h5 className="text-emerald-500 text-sm">Your name</h5>
              <div className="flex items-center">
                {isEditingName ? (
                  <div className="flex items-center w-full py-4">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="bg-transparent border-b-2 border-emerald-500 text-white focus:outline-none flex-grow"
                      maxLength="25"
                    />
                    <span className="text-gray-400 ml-2 text-sm">
                      {name.length}/25
                    </span>
                    <MdCheck
                      className="ml-2 text-emerald-500 cursor-pointer"
                      onClick={handleNameSave}
                    />
                  </div>
                ) : (
                  <div className="flex items-center w-full">
                    <h1 className="text-xl py-4 flex-grow">
                      {name || "Account Name"}
                    </h1>
                    <MdEdit
                      className="ml-2 cursor-pointer"
                      onClick={handleNameEdit}
                    />
                  </div>
                )}
              </div>
              <p className="text-sm">
                This is not your username or PIN. This name will be visible to
                your ChatyApp contacts.
              </p>
              <h5 className="text-emerald-500 text-xs py-4">About</h5>

              <div className="flex items-center">
                {isEditingAbout ? (
                  <div className="flex items-center w-full">
                    <input
                      type="text"
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                      className="bg-transparent border-b-2 border-emerald-500 text-white focus:outline-none flex-grow"
                      maxLength="50"
                    />
                    <span className="text-gray-400 ml-2  text-sm">
                      {about.length}/50
                    </span>
                    <MdCheck
                      className="ml-2 text-emerald-500 cursor-pointer"
                      onClick={handleAboutSave}
                    />
                  </div>
                ) : (
                  <div className="flex items-center w-full">
                    <h1 className="text-xl flex-grow">
                      {about || "Hey, I'm using ChatyApp."}
                    </h1>
                    <MdEdit
                      className="ml-2 cursor-pointer"
                      onClick={handleAboutEdit}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default UserProfile;
