export const baseUrl = "http://52.66.245.210:5000/api";

export const imgUrl = "http://52.66.245.210:5000";

const handleResponse = async (response) => {
  const data = await response.json();
  if (!response.ok) {
    const message = data?.message || "An error occurred...";
    return { error: true, message };
  }
  return data;
};

const request = async (url, method, body = null, requiresAuth = true) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (requiresAuth) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }

  const options = {
    method,
    headers,
    credentials: "include",
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, options);
    return await handleResponse(response);
  } catch (error) {
    return { error: true, message: error.message };
  }
};

export const getRequest = async (url, requiresAuth = true) => {
  return request(`${baseUrl}${url}`, "GET", null, requiresAuth);
};

export const postRequest = async (url, body = {}, requiresAuth = true) => {
  return request(`${baseUrl}${url}`, "POST", body, requiresAuth);
};

export const putRequest = async (url, body = {}, requiresAuth = true) => {
  return request(`${baseUrl}${url}`, "PUT", body, requiresAuth);
};

export const deleteRequest = async (url, body = {}, requiresAuth = true) => {
  return request(`${baseUrl}${url}`, "DELETE", body, requiresAuth);
};

export const logout = async () => {
  const response = await postRequest("/auth/logout");
  if (!response.error) {
    console.log("Successfully logged out");
    localStorage.removeItem("token");
  } else {
    console.error("Logout failed:", response.message);
  }
  return response;
};

export const formatDateTime = (isoString) => {
  const options = {
    // day: "2-digit",
    // month: "short",
    // year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return new Date(isoString).toLocaleString("en-US", options);
};
