import React, { useState, useEffect, useContext } from "react";
import LeftMenu from "../components/LeftMenu";
import ChatDetail from "../components/ChatDetail";
import DefaultPage from "../components/DefaultPage";
import LoadingScreen from "../components/LoadingScreen";
import { AuthContext } from "../context/AuthContext";
import AllContact from "../components/AllContact";
import UserDetails from "../components/UserDetails";
import { ManageUsers } from "../components/ManageUsers";

function ChatWeb() {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);
  const { user } = useContext(AuthContext);

  const { userChats, fetchSelectedUsers } = ManageUsers(user);

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [chatsUsers, setChatsUsers] = useState([]);

  const addNewChat = () => {
    fetchSelectedUsers();
  };

  const updateChatList = (chatId, lastMessage, lastMessageTime) => {
    setChatsUsers((prevChats) =>
      prevChats.map((chat) =>
        chat._id === chatId ? { ...chat, lastMessage, lastMessageTime } : chat
      )
    );
  };

  useEffect(() => {
    if (Array.isArray(userChats)) {
      setChatsUsers(userChats);
    } else {
      setChatsUsers([]);
    }
  }, [userChats]);

  const {
    showUserList,
    setShowUserList,
    showAllUsers,
    setShowAllUsers,
    handleSearchUsers,
    toggleAllUsersColumn,
  } = ManageUsers(user);

  const handleChatSelect = (chat) => {
    console.log("Selected Chat Object:", chat);
    setSelectedChat(chat);
  };

  const handleProfileClick = () => {
    setShowUserDetails(true);
  };

  const handleCloseUserDetails = () => {
    setShowUserDetails(false);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      if (progress >= 100) setLoading(false);
      else {
        const increment = Math.floor(Math.random() * (10 + 1)) + 7;
        setProgress(progress + increment);
      }
    }, 300);
    return () => clearTimeout(id);
  }, [progress]);

  return (
    <>
      {loading ? (
        <LoadingScreen progress={progress} />
      ) : (
        <div className="w-screen h-screen overflow-hidden">
          <div className="flex justify-start whatsapp-bp:justify-center items-center bg-[#111a21] h-screen">
            <div className="bg-[#111a21] min-w-[340px] max-w-[500px] w-100 h-100">
              <LeftMenu
                onChatSelect={handleChatSelect}
                userEmail={user?.username}
                user={user}
                onSearchUsers={handleSearchUsers}
                toggleAllUsersColumn={toggleAllUsersColumn}
                chatsUsers={chatsUsers}
                addNewChat={addNewChat}
                updateChatList={updateChatList}
              />
            </div>
            <AllContact
              showAllUsers={showAllUsers}
              setShowAllUsers={setShowAllUsers}
              user={user}
              addNewChat={addNewChat}
            />
            <div className="bg-[#222f35] min-w-[415px] max-w-[1120px] w-100 h-100">
              {showUserList ? (
                <div className="overflow-y-scroll p-4">
                  <button
                    onClick={() => setShowUserList(false)}
                    className="text-white bg-red-500 p-2 mb-4 rounded-lg"
                  >
                    Close
                  </button>
                </div>
              ) : selectedChat ? (
                <div className="bg-[#222f35] flex-1 h-full">
                  {showUserDetails ? (
                    <UserDetails

                    chat={selectedChat}
                      // mediaImages={mediaImages}
                      // links={links}

                      onClose={handleCloseUserDetails}
                    />
                  ) : (
                    <ChatDetail
                      onProfileClick={handleProfileClick}
                      selectedChat={selectedChat}
                      updateChatList={updateChatList}
                      addNewChat={addNewChat}
                    />
                  )}
                </div>
              ) : (
                <DefaultPage />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatWeb;
