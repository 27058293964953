import React, { useState } from "react";
import Chat from "./Chat";
import { ImFolderDownload } from "react-icons/im";
import { BiFilter } from "react-icons/bi";
import { ManageUsers } from "../components/ManageUsers";
import { imgUrl } from "../utils/services";

function Chats({
  onChatSelect,
  onArchivedClick,
  users = [],
  user,
  addNewChat,
}) {
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedChatId, setSelectedChatId] = useState(null);

  const { handleSearchUsers } = ManageUsers(user);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    handleSearchUsers(e.target.value);
  };

  let filteredUsers = Array.isArray(users)
    ? users.filter(
        (user) =>
          user.chatName &&
          user.chatName.toLowerCase().includes(search.toLowerCase())
      )
    : [];

  if (filter) {
    filteredUsers = filteredUsers.filter((user) => user.unreadMsgs > 0);
  }

  const handleChatClick = (user) => {
    setSelectedChatId(user._id);
    onChatSelect(user);
  };

  return (
    <div className="flex flex-col overflow-y-scroll cursor-pointer h-100">
      <h1 className="text-white text-2xl ml-2 mt-2 font-semibold">Chats</h1>
      <div className="flex justify-between items-center h-[60px] px-2 -mt-2 -mb-1">
        <input
          type="text"
          placeholder="Search or start a new chat"
          className="rounded-lg bg-[#202d33] text-[#8796a1] text-sm font-light outline-none p-2 px-4 py-2 w-screen h-[35px] placeholder:text-[#8796a1] placeholder:text-sm placeholder:font-light"
          value={search}
          onChange={handleSearchChange}
        />

        <button
          className={`text-2xl m-2 p-1 rounded-full ${
            filter
              ? "bg-emerald-500 text-white rounded-full hover:bg-emerald-700"
              : "text-[#8796a1] hover:bg-[#3c454c]"
          }`}
          onClick={() => setFilter(!filter)}
        >
          <BiFilter />
        </button>
      </div>

      <div
        className="flex justify-between items-center w-100 min-h-[55px] px-3 hover:bg-[#202d33]"
        onClick={onArchivedClick}
      >
        <div className="flex justify-around items-center w-[150px]">
          <span className="text-emerald-500 text-lg">
            <ImFolderDownload />
          </span>
          <h1 className="text-white">Archived</h1>
        </div>
        <p className="text-emerald-500 text-xs font-light">7</p>
      </div>

      {/* {filteredUsers.map((user) => (
        <div key={user._id} onClick={() => handleChatClick(user)}>
          <Chat
            pp={
              user.chatIcon
                ? `${imgUrl}${user.chatIcon}`
                : `https://avatar.iran.liara.run/username?username=${user.chatName}`
            }
            user={user._id}
            contact={user.chatName || "Unknown"}
            msg={user.lastMessage || "No message"}
            time={
              user.lastMessageTime
                ? new Date(user.lastMessageTime).toLocaleTimeString()
                : "N/A"
            }
            unreadMsgs={user.unreadMsgs || 0}
            active={selectedChatId === user._id}
            pinned={user.pinned}
            addNewChat={addNewChat}
          />
        </div>
      ))} */}
      {filteredUsers.map((user) => (
        <div key={user._id} onClick={() => handleChatClick(user)}>
          <Chat
            pp={
              user.chatIcon
                ? `${imgUrl}${user.chatIcon}`
                : `https://avatar.iran.liara.run/username?username=${user.chatName}`
            }
            user={user._id}
            contact={user.chatName || "Unknown"}
            msg={user.lastMessage || "No message"}
            time={
              user.lastMessageTime
                ? new Date(user.lastMessageTime).toLocaleTimeString()
                : "N/A"
            }
            unreadMsgs={user.unreadMsgs || 0}
            active={selectedChatId === user._id}
            pinned={user.pinned}
            addNewChat={addNewChat}
            onClick={() => handleChatClick(user)}
          />
        </div>
      ))}
    </div>
  );
}

export default Chats;
