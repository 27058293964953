import React, { useContext, useEffect, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { BiChevronRight } from "react-icons/bi";
import { getRequest } from "../../utils/services";
import { AuthContext } from "../../context/AuthContext";

const Privacy = ({ onBack }) => {
  const [groupCount, setGroupCount] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchGroupCount = async () => {
      const response = await getRequest(
        `/conversations/countGroupConversations/${user._id}`
      );
      if (!response.error) {
        setGroupCount(response.groupConversationCount || 0);
      } else {
        console.error("Failed to fetch group cont: " + response.error);
      }
    };

    fetchGroupCount();
  }, []);

  const privacyMenu = [
    {
      label: "Groups",
      description: `${groupCount} ${groupCount === 1 ? "group" : "groups"}`,
    },
    {
      label: "Blocked contacts",
      description: "15",
    },
  ];

  return (
    <div className="min-h-screen bg-[#111a21] text-gray-300 p-4">
      <div
        className="flex justify-start items-center p-3 -ml-3 cursor-pointer"
        onClick={onBack}
      >
        <GoArrowLeft className="mr-4 text-lg hover:bg-gray-700 rounded-full w-6 h-6" />
        <span className="text-lg">Privacy</span>
      </div>
      <div className="mt-4 font-light">
        {privacyMenu.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between p-3 rounded cursor-pointer hover:bg-gray-700"
          >
            <div>
              <span className="block text-lg">{item.label}</span>
              <span className="block text-sm text-gray-500">
                {item.description}
              </span>
            </div>
            <BiChevronRight className="text-gray-400" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Privacy;
