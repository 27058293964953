import React, { useState, useRef, useEffect } from "react";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BiHappy } from "react-icons/bi";
import { BsFillMicFill } from "react-icons/bs";
import { MdSend } from "react-icons/md";
import Button from "./Common/RoundedBtn";
import { FaPaperclip, FaMicrophone, FaSmile, FaTimes } from "react-icons/fa";
import EmojiPicker from "./Emoji";
import ReplyManager from "./ReplyManager";

function ChatInput({
  onEmojiClick,
  onEmojiSelect,
  onAttachmentToggle,
  onInputSubmit,
  onVoiceMessageSubmit,
  typing,
  inputRef,
  replyingTo,
  onSubmit,
}) {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [hasText, setHasText] = useState(false);
  const [isAttachmentActive, setIsAttachmentActive] = useState(false);
  const [chunks, setChunks] = useState([]);

  const handleEmojiClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
    setHasText(true);
    onEmojiClick();
  };

  const handleEmojiSelect = (emoji) => {
    if (inputRef.current) {
      inputRef.current.value += emoji;
      inputRef.current.focus();
      onEmojiSelect(emoji);
    }
  };

  const toggleAttachmentDialog = () => {
    setIsAttachmentActive((prev) => !prev);
    setHasText(true);
    onAttachmentToggle();
  };

  const handleInputChange = () => {
    if (inputRef.current) {
      const inputHasText = inputRef.current.value.length > 0;
      setHasText(inputHasText);
      typing(inputRef.current.value.length > 0);
    }
  };
  const handleTextInputSubmit = () => {
    if (inputRef.current && inputRef.current.value) {
      const messageContent = inputRef.current.value;
      const referenceMessage = replyingTo
        ? `Replying to: ${replyingTo.msg}\n`
        : "";

      const message = {
        content: referenceMessage + messageContent,
        replyTo: replyingTo ? { id: replyingTo.id, msg: replyingTo.msg } : null,
      };

      console.log("Handling Input Submit:", message);
      onInputSubmit(message);
      inputRef.current.value = "";
      setHasText(false);
      setShowEmojiPicker(false);
      setIsAttachmentActive(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleTextInputSubmit();
    }
  };
  const startRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
          recorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              const audioBlob = new Blob([e.data], { type: "audio/wav" });
              onVoiceMessageSubmit(audioBlob);
            }
          };
          recorder.start();
          setIsRecording(true);
        })
        .catch((err) => console.error("Error accessing audio devices", err));
    }
  };

  useEffect(() => {
    console.log("Replying To in ChatInput:", replyingTo);
  }, [replyingTo]);

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  return (
    <div className="flex flex-col bg-[#202d33] w-full">
      {replyingTo && (
        <ReplyManager
          replyingTo={replyingTo}
          onCancelReply={() => onSubmit(null)}
        />
      )}

      <div className="flex items-center w-full h-[70px] p-2">
        <Button icon={<BiHappy />} onClick={handleEmojiClick} />
        <span className="mr-2">
          <Button
            icon={<AiOutlinePaperClip />}
            onClick={toggleAttachmentDialog}
          />
        </span>
        <input
          type="text"
          placeholder="Type a message"
          className="bg-[#2c3943] rounded-lg outline-none text-sm text-neutral-200 w-full h-full px-3 placeholder:text-sm placeholder:text-[#8796a1]"
          onChange={handleInputChange}
          ref={inputRef}
          onKeyDown={handleKeyDown}
        />
        <span className="ml-2">
        {hasText || showEmojiPicker || isAttachmentActive ? (
            <Button icon={<MdSend />} onClick={handleTextInputSubmit} />
          ) : (
            <Button
              icon={isRecording ? <MdSend /> : <BsFillMicFill />}
              onClick={isRecording ? stopRecording : startRecording}
              className={`p-2 rounded-full text-white ${isRecording ? "bg-green-500 hover:bg-green-700" : "bg-[#2a3137] hover:bg-[#3e4e5a]"
                }`}
            />
          )}
        </span>

        {/* button  is in here */}

        {showEmojiPicker && (
          <EmojiPicker
            onClose={handleEmojiClick}
            onEmojiSelect={handleEmojiSelect}
          />
        )}
      </div>
    </div>
  );
}

export default ChatInput;