import React, { useRef, useEffect, useState } from "react";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";

function MessageDialog({
  onClose,
  messageType,
  onEdit,
  onDelete,
  onReact,
  onPinMessage,
  onPin,
  isPinned,
  onForward,
  onReply,
  isSent,
  messageId,
}) {
  const dialogRef = useRef(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleDeleteClick = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirm = (option) => {
    onDelete(option);
    setIsDeleteConfirmationOpen(false);
    onClose();
  };

  const handleDeleteCancel = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const handleReply = () => {
    onReply();
    onClose(); // Close dialog after replying
  };

  const handleReact = () => {
    onReact();
    onClose(); // Close dialog after reacting
  };

  return (
    <div
      ref={dialogRef}
      className="absolute z-50 top-0 right-0 mt-2 mr-2 border rounded-md shadow-lg"
      style={{ backgroundColor: "#1c4f3f" }} // Adjust styling as needed
    >
      {isSent && messageType === "text" && (
        <button
          onClick={onEdit}
          className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left"
        >
          Edit
        </button>
      )}
      <button
        onClick={handleDeleteClick}
        className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left"
      >
        Delete
      </button>
      <button
        onClick={handleReact}
        className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left"
      >
        React
      </button>
      <button
        onClick={() => onPin(messageId)}
        className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left"
      >
        {isPinned ? "Unpin" : "Pin"}
      </button>
      <button
        onClick={onForward}
        className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left"
      >
        Forward
      </button>
      {/* <button
        onClick={handleReply}
        className="block px-4 py-2 text-white hover:bg-[#14543e] w-full text-left"
      >
        Reply
      </button> */}

      {/* Show the delete confirmation dialog */}
      {isDeleteConfirmationOpen && (
        <DeleteConfirmationDialog
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          isSent={isSent}
        />
      )}
    </div>
  );
}

export default MessageDialog;
