import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { FaUser, FaInfoCircle } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";

const SignUpProfilePage = ({ onProfileUpdate }) => {
  const [username, setUsername] = useState("");
  const [about, setAbout] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [error, setError] = useState("");
  const { setIsAuthenticated, setUser } = useContext(AuthContext);

  const handleProfilePictureChange = (e) => {
    setProfilePicture(e.target.files[0]);
    console.log("Profile picture selected:", e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username) {
      setError("Username is required");
      return;
    }

    console.log("Submitting profile data:", {
      username,
      about,
      profilePicture,
    });

    const formData = new FormData();
    formData.append("username", username);
    formData.append("about", about);
    if (profilePicture) formData.append("profilePicture", profilePicture);

    const token = localStorage.getItem("token");
    console.log("Token:", token);
    if (!token) {
      alert("No authentication token found");
      return;
    }
    try {
      const response = await axios.post(
        "http://52.66.245.210:5000/api/users/updateProfile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Profile updated:", response.data);
      localStorage.setItem("token", response.data.token);
      setIsAuthenticated(true);

      setUser((prevUser) => ({
        ...prevUser,
        username: response.data.username,
        about: response.data.about,
        profilePicture: response.data.profilePicture,
      }));

      if (response.data.success) {
        onProfileUpdate();
        setError("");
      }
    } catch (error) {
      console.error(
        "Error updating profile:",
        error.response ? error.response.data : error.message
      );
      setError(error.response?.data?.message || "An error occurred");
    }
  };

  return (

    <div className="flex justify-center items-center min-h-screen overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-600 backdrop-blur-md">
      <form onSubmit={handleSubmit} className="bg-gray-900 p-8 rounded-lg shadow-lg max-w-sm w-full">

        <h2 className="text-2xl font-bold text-center mb-6 text-gradient bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-emerald-500">
          Complete Your Profile
        </h2>

        <div className="flex justify-center align-middle mb-6">
          <img
            src={
              profilePicture
                ? URL.createObjectURL(profilePicture)
                : "/default-profile.png"
            }
            alt="Profile Preview"
            className="w-24 h-24 rounded-full border-4 border-gray-400 text-slate-300 text-center"
          />
        </div>
        <div className="relative mb-4">
          <input
            type="file"
            onChange={handleProfilePictureChange}
            className="w-full p-6 border border-gray-700 rounded bg-gray-800 text-white"
          />
        </div>

        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              console.log("Username updated:", e.target.value);
            }}
            required
            className="w-full p-6 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 pl-10"
          />
          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
            <FaUser size={20} />
          </span>{" "}
        </div>

        <div className="relative mb-4">
          <textarea
            placeholder="About you"
            value={about}
            onChange={(e) => {
              setAbout(e.target.value);
              console.log("About updated:", e.target.value);
            }}
            className="w-full p-6 border border-gray-700 rounded bg-gray-800 text-white placeholder-gray-400 pl-10"
          />
          <FaInfoCircle
            size={20}
            className="absolute inset-y-0 left-3 mt-9 transform -translate-y-1/2 text-gray-400"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-gradient-to-r from-green-500 to-emerald-600 text-white py-3 rounded-lg font-bold hover:from-green-600 hover:to-emerald-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-900 transition duration-200"
        >
          Save Profile
        </button>
      </form>
    </div>
  );
};

export default SignUpProfilePage;
