import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../utils/services";
import { io } from "socket.io-client";

const socket = io("http://52.66.245.210:5000");

export const ManageUsers = (user) => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [showUserList, setShowUserList] = useState(false);
  const [users, setUsers] = useState([]);
  const [chatsUsers, setChatsUsers] = useState([]);
  const [userChats, setUserChats] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [showSelection, setShowSelection] = useState(true);
  const [showAllUsers, setShowAllUsers] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          throw new Error("User not authenticated");
        }

        const response = await axios.get(`${baseUrl}/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const filteredUsers = response.data.filter(
          (user) =>
            !chatsUsers.some((selectedUser) => selectedUser._id === user._id)
        );

        setAllUsers(filteredUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
        if (error.response?.status === 401) {
          console.log("Redirect to login or show a message");
        }
      }
    };

    fetchUsers();
  }, [chatsUsers]);

  const sortConversationsByDate = (conversations) => {
    return conversations.sort((a, b) => {
      // Pinned conversations
      if (a.pinned && !b.pinned) return -1;
      if (!a.pinned && b.pinned) return 1;

      const dateA = new Date(a.lastMessageTime || a.updatedAt || a.createdAt);
      const dateB = new Date(b.lastMessageTime || b.updatedAt || b.createdAt);

      if (!a.lastMessageTime && b.lastMessageTime) return 1;
      if (a.lastMessageTime && !b.lastMessageTime) return -1;

      return dateB - dateA;
    });
  };

  const fetchSelectedUsers = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/conversations/getConversations/${user._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log("Selected users Data:", response.data);

      const sortedConversations = sortConversationsByDate(
        response.data.conversations
      );

      const userConversations = sortedConversations.map((conv) => {
        const selectedUser = conv.participants.find(
          (participant) => participant._id !== user._id
        );
        console.log("Selected User:", selectedUser);
        return selectedUser;
      });

      console.log("Sorted Conversations:", sortedConversations);

      setChatsUsers(userConversations);
      setUserChats(sortedConversations);
    } catch (error) {
      console.error("Error fetching conversations:", error);
    }
  };

  useEffect(() => {
    if (user && user._id) {
      fetchSelectedUsers();
    }
  }, [user]);

  useEffect(() => {
    const handleNewConversation = (conversation) => {
      console.log("New conversation received:", conversation);
      setUserChats((prevChats) => {
        const existing = prevChats.find(
          (chat) => chat._id === conversation._id
        );
        if (!existing) return [...prevChats, conversation];
        return prevChats; // Avoid duplicates
      });
    };
    const handleNewGroupConversation = (groupConversation) => {
      console.log("New group conversation received:", groupConversation);
      setUserChats((prevChats) => {
        const existing = prevChats.find(
          (chat) => chat._id === groupConversation._id
        );
        if (!existing) return [...prevChats, groupConversation];
        return prevChats; // Avoid duplicates
      });
    };

    socket.on("connect", () => {
      console.log("Socket connected:", socket.id);
    });

    socket.on("newConversation", handleNewConversation);
    socket.on("newGroupConversation", handleNewGroupConversation);

    return () => {
      socket.off("newConversation", handleNewConversation);
      socket.off("newGroupConversation", handleNewGroupConversation);
    };
  }, []);

  const handleChatSelect = (users) => {
    setSelectedChat(users);
    setShowUserList(false);
  };

  const handleSearchUsers = async () => {
    try {
      const response = await axios.get(`${baseUrl}/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const filteredUsers = response.data.filter(
        (user) =>
          !chatsUsers.some((selectedUser) => selectedUser._id === user._id)
      );

      setUsers(filteredUsers);
      setShowUserList(true);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleUserAdd = async (userToAdd) => {
    if (!chatsUsers.some((u) => u._id === userToAdd._id)) {
      try {
        await axios.post(
          `${baseUrl}/conversations/addUserToConversation`,
          {
            userId: user._id,
            selectedUserId: userToAdd._id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setChatsUsers((prevChatsUsers) => [...prevChatsUsers, userToAdd]);
        setAllUsers((prevAllUsers) =>
          prevAllUsers.filter((u) => u._id !== userToAdd._id)
        );
      } catch (error) {
        console.error("Error adding user to conversation list:", error);
      }
    }
  };

  const toggleAllUsersColumn = () => {
    setShowAllUsers(!showAllUsers);
  };

  return {
    selectedChat,
    showUserList,
    setShowUserList,
    users,
    chatsUsers,
    userChats,
    allUsers,
    showSelection,
    showAllUsers,
    setShowAllUsers,
    handleChatSelect,
    handleSearchUsers,
    handleUserAdd,
    toggleAllUsersColumn,
    fetchSelectedUsers,
  };
};
