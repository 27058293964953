import React, { useEffect, useRef } from "react";

function ReactionDialog({ onClose, onSelectReaction }) {
  const reactions = ["👍", "❤️", "😂", "😮", "😢", "😡"];
  const dialogRef = useRef(null);

  const handleReactionClick = (reaction) => {
    onSelectReaction(reaction);
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        console.log("Clicked outside the dialog, closing...");
        onClose();
      } else {
        console.log("Clicked inside the dialog.");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={dialogRef}
      className="absolute z-50 top-0 right-0 mt-2 mr-2 border rounded-full shadow-lg bg-white"
    >
      <div className="flex space-x-2 p-4">
        {reactions.map((reaction) => (
          <button
            key={reaction}
            onClick={() => handleReactionClick(reaction)}
            className="text-2xl"
          >
            {reaction}
          </button>
        ))}
      </div>
    </div>
  );
}

export default ReactionDialog;

// import React, { useEffect, useRef } from 'react';

// function ReactionDialog({ onClose, onSelectReaction, onRemoveReaction, hasReaction }) {
//   const reactions = ['👍', '❤️', '😂', '😮', '😢', '😡'];
//   const dialogRef = useRef(null);

//   const handleReactionClick = (reaction) => {
//     onSelectReaction(reaction);
//     onClose();
//   };

//   const handleRemoveClick = () => {
//     onRemoveReaction();
//     onClose();
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dialogRef.current && !dialogRef.current.contains(event.target)) {
//         onClose();
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [onClose]);

//   return (
//     <div
//       ref={dialogRef}
//       className="absolute z-50 top-0 right-0 mt-2 mr-2 border rounded-full shadow-lg bg-white"
//     >
//       <div className="flex space-x-2 p-4">
//         {reactions.map((reaction) => (
//           <button
//             key={reaction}
//             onClick={() => handleReactionClick(reaction)}
//             className="text-2xl"
//           >
//             {reaction}
//           </button>
//         ))}
//         {hasReaction && (
//           <button
//             onClick={handleRemoveClick}
//             className="text-sm text-red-600"
//           >
//             Remove Reaction
//           </button>
//         )}
//       </div>
//     </div>
//   );
// }

// export default ReactionDialog;
